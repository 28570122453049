.pf-c-title {
  --pf-c-title--FontFamily: var(--pf-global--FontFamily--heading--sans-serif);
  --pf-c-title--m-4xl--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-title--m-4xl--FontSize: var(--pf-global--FontSize--4xl);
  --pf-c-title--m-4xl--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-title--m-3xl--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-title--m-3xl--FontSize: var(--pf-global--FontSize--3xl);
  --pf-c-title--m-3xl--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-title--m-2xl--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-title--m-2xl--FontSize: var(--pf-global--FontSize--2xl);
  --pf-c-title--m-2xl--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-title--m-xl--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-title--m-xl--FontSize: var(--pf-global--FontSize--xl);
  --pf-c-title--m-xl--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-title--m-lg--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-title--m-lg--FontSize: var(--pf-global--FontSize--lg);
  --pf-c-title--m-lg--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-title--m-md--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-title--m-md--FontSize: var(--pf-global--FontSize--md);
  --pf-c-title--m-md--FontWeight: var(--pf-global--FontWeight--normal);
  font-family: var(--pf-c-title--FontFamily);
  word-break: break-word;
}
.pf-c-title.pf-m-4xl {
  font-size: var(--pf-c-title--m-4xl--FontSize);
  font-weight: var(--pf-c-title--m-4xl--FontWeight);
  line-height: var(--pf-c-title--m-4xl--LineHeight);
}
.pf-c-title.pf-m-3xl {
  font-size: var(--pf-c-title--m-3xl--FontSize);
  font-weight: var(--pf-c-title--m-3xl--FontWeight);
  line-height: var(--pf-c-title--m-3xl--LineHeight);
}
.pf-c-title.pf-m-2xl {
  font-size: var(--pf-c-title--m-2xl--FontSize);
  font-weight: var(--pf-c-title--m-2xl--FontWeight);
  line-height: var(--pf-c-title--m-2xl--LineHeight);
}
.pf-c-title.pf-m-xl {
  font-size: var(--pf-c-title--m-xl--FontSize);
  font-weight: var(--pf-c-title--m-xl--FontWeight);
  line-height: var(--pf-c-title--m-xl--LineHeight);
}
.pf-c-title.pf-m-lg {
  font-size: var(--pf-c-title--m-lg--FontSize);
  font-weight: var(--pf-c-title--m-lg--FontWeight);
  line-height: var(--pf-c-title--m-lg--LineHeight);
}
.pf-c-title.pf-m-md {
  font-size: var(--pf-c-title--m-md--FontSize);
  font-weight: var(--pf-c-title--m-md--FontWeight);
  line-height: var(--pf-c-title--m-md--LineHeight);
}

.pf-m-overpass-font .pf-c-title {
  --pf-c-title--m-md--FontWeight: var(--pf-global--FontWeight--semi-bold);
  --pf-c-title--m-lg--FontWeight: var(--pf-global--FontWeight--semi-bold);
}