.pf-c-login__footer, .pf-c-login__header {
  --pf-global--Color--100: var(--pf-global--Color--light-100);
  --pf-global--Color--200: var(--pf-global--Color--light-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--light-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--light-100);
  --pf-global--link--Color: var(--pf-global--link--Color--light);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--light);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--dark-100);
}
.pf-c-login__footer .pf-c-card, .pf-c-login__header .pf-c-card {
  --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-200);
}
.pf-c-login__footer .pf-c-button, .pf-c-login__header .pf-c-button {
  --pf-c-button--m-primary--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--hover--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--focus--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--active--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-secondary--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--BorderColor: var(--pf-global--Color--light-100);
}

.pf-c-login {
  --pf-c-login--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-login--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-login--xl--BackgroundImage: none;
  --pf-c-login__container--xl--GridColumnGap: var(--pf-global--spacer--3xl);
  --pf-c-login__container--MaxWidth: 31.25rem;
  --pf-c-login__container--xl--MaxWidth: none;
  --pf-c-login__container--PaddingLeft: 6.125rem;
  --pf-c-login__container--PaddingRight: 6.125rem;
  --pf-c-login__container--xl--GridTemplateColumns: 34rem minmax(auto, 34rem);
  --pf-c-login__header--MarginBottom: var(--pf-global--spacer--md);
  --pf-c-login__header--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-login__header--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-login__header--xl--MarginBottom: var(--pf-global--spacer--2xl);
  --pf-c-login__header--xl--MarginTop: var(--pf-global--spacer--3xl);
  --pf-c-login__header--c-brand--MarginBottom: var(--pf-global--spacer--lg);
  --pf-c-login__header--c-brand--xl--MarginBottom: var(--pf-global--spacer--2xl);
  --pf-c-login__main--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-login__main--MarginBottom: var(--pf-global--spacer--lg);
  --pf-c-login__main-header--PaddingTop: var(--pf-global--spacer--2xl);
  --pf-c-login__main-header--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-login__main-header--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-login__main-header--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-login__main-header--md--PaddingRight: var(--pf-global--spacer--2xl);
  --pf-c-login__main-header--md--PaddingLeft: var(--pf-global--spacer--2xl);
  --pf-c-login__main-header--ColumnGap: var(--pf-global--spacer--md);
  --pf-c-login__main-header--RowGap: var(--pf-global--spacer--md);
  --pf-c-login__main-header-desc--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-login__main-header-desc--md--MarginBottom: 0;
  --pf-c-login__main-header-desc--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-login__main-body--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-login__main-body--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-login__main-body--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-login__main-body--md--PaddingRight: var(--pf-global--spacer--2xl);
  --pf-c-login__main-body--md--PaddingLeft: var(--pf-global--spacer--2xl);
  --pf-c-login__main-footer--PaddingBottom: var(--pf-global--spacer--3xl);
  --pf-c-login__main-footer--c-title--MarginBottom: var(--pf-global--spacer--md);
  --pf-c-login__main-footer-links--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-login__main-footer-links--PaddingRight: var(--pf-global--spacer--3xl);
  --pf-c-login__main-footer-links--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-login__main-footer-links--PaddingLeft: var(--pf-global--spacer--3xl);
  --pf-c-login__main-footer-links-item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-login__main-footer-links-item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-login__main-footer-links-item--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-login__main-footer-links-item-link-svg--Fill: var(--pf-global--icon--Color--light);
  --pf-c-login__main-footer-links-item-link-svg--Width: var(--pf-global--icon--FontSize--lg);
  --pf-c-login__main-footer-links-item-link-svg--Height: var(--pf-global--icon--FontSize--lg);
  --pf-c-login__main-footer-links-item-link-svg--hover--Fill: var(--pf-global--icon--Color--dark);
  --pf-c-login__main-footer-band--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-login__main-footer-band--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-login__main-footer-band--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-login__main-footer-band--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-login__main-footer-band--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-login__main-footer-band-item--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-login__footer--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-login__footer--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-login__footer--c-list--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-login__footer--c-list--xl--PaddingTop: var(--pf-global--spacer--2xl);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-top: var(--pf-c-login--PaddingTop);
  padding-bottom: var(--pf-c-login--PaddingBottom);
}
@media (min-width: 1200px) {
  .pf-c-login {
    --pf-c-login__container--MaxWidth: var(--pf-c-login__container--xl--MaxWidth);
  }
}
@media (min-width: 576px) {
  .pf-c-login {
    --pf-c-login__header--PaddingRight: 0;
    --pf-c-login__header--PaddingLeft: 0;
  }
}
@media (min-width: 1200px) {
  .pf-c-login {
    --pf-c-login__header--MarginBottom: var(--pf-c-login__header--xl--MarginBottom);
    --pf-c-login__header--c-brand--MarginBottom: var(--pf-c-login__header--c-brand--xl--MarginBottom);
  }
}
@media (min-width: 1200px) {
  .pf-c-login {
    --pf-c-login__main--MarginBottom: 0;
  }
}
@media (min-width: 768px) {
  .pf-c-login {
    --pf-c-login__main-header--PaddingRight: var(--pf-c-login__main-header--md--PaddingRight);
    --pf-c-login__main-header--PaddingLeft: var(--pf-c-login__main-header--md--PaddingLeft);
    --pf-c-login__main-header-desc--MarginBottom: var(--pf-c-login__main-header-desc--md--MarginBottom);
  }
}
@media (min-width: 768px) {
  .pf-c-login {
    --pf-c-login__main-body--PaddingRight: var(--pf-c-login__main-body--md--PaddingRight);
    --pf-c-login__main-body--PaddingLeft: var(--pf-c-login__main-body--md--PaddingLeft);
  }
}
@media (min-width: 576px) {
  .pf-c-login {
    --pf-c-login__footer--PaddingRight: 0;
    --pf-c-login__footer--PaddingLeft: 0;
  }
}
@media (min-width: 1200px) {
  .pf-c-login {
    --pf-c-login__footer--c-list--PaddingTop: var(--pf-c-login__footer--c-list--xl--PaddingTop);
  }
}
@media (min-width: 1200px) {
  .pf-c-login {
    background-image: var(--pf-c-login--xl--BackgroundImage);
  }
}
@media (min-width: 576px) {
  .pf-c-login {
    align-items: center;
  }
}

.pf-c-login__container {
  width: 100%;
  max-width: var(--pf-c-login__container--MaxWidth);
}
@media (min-width: 1200px) {
  .pf-c-login__container {
    display: grid;
    justify-content: center;
    grid-column-gap: var(--pf-c-login__container--xl--GridColumnGap);
    grid-template-columns: var(--pf-c-login__container--xl--GridTemplateColumns);
    grid-template-areas: "main header" "main footer" "main .";
    padding-right: var(--pf-c-login__container--PaddingRight);
    padding-left: var(--pf-c-login__container--PaddingLeft);
  }
}

.pf-c-login__header {
  color: var(--pf-global--Color--100);
  grid-area: header;
  padding-right: var(--pf-c-login__header--PaddingRight);
  padding-left: var(--pf-c-login__header--PaddingLeft);
}
@media (min-width: 1200px) {
  .pf-c-login__header {
    margin-top: var(--pf-c-login__header--xl--MarginTop);
  }
}
.pf-c-login__header .pf-c-brand {
  margin-bottom: var(--pf-c-login__header--c-brand--MarginBottom);
}

.pf-c-login__main {
  margin-bottom: var(--pf-c-login__main--MarginBottom);
  background-color: var(--pf-c-login__main--BackgroundColor);
  grid-area: main;
}
.pf-c-login__main > :first-child:not(.pf-c-login__main-header) {
  padding-top: var(--pf-c-login__main-header--PaddingTop);
}
.pf-c-login__main > :last-child:not(.pf-c-login__main-footer) {
  padding-bottom: var(--pf-c-login__main-footer--PaddingBottom);
}

.pf-c-login__main-header {
  display: grid;
  grid-template-columns: 100%;
  column-gap: var(--pf-c-login__main-header--ColumnGap);
  row-gap: var(--pf-c-login__main-header--RowGap);
  align-items: center;
  padding: var(--pf-c-login__main-header--PaddingTop) var(--pf-c-login__main-header--PaddingRight) var(--pf-c-login__main-header--PaddingBottom) var(--pf-c-login__main-header--PaddingLeft);
}
@media (min-width: 768px) {
  .pf-c-login__main-header {
    grid-template-columns: 1fr auto;
  }
}
.pf-c-login__main-header .pf-c-dropdown {
  grid-column: auto;
  grid-row: auto;
}
@media (min-width: 768px) {
  .pf-c-login__main-header .pf-c-dropdown {
    grid-column: 2/3;
    grid-row: 1;
  }
}

.pf-c-login__main-header-desc {
  margin-bottom: var(--pf-c-login__main-header-desc--MarginBottom);
  font-size: var(--pf-c-login__main-header-desc--FontSize);
  grid-column: 1/-1;
}

.pf-c-login__main-body {
  padding-right: var(--pf-c-login__main-body--PaddingRight);
  padding-bottom: var(--pf-c-login__main-body--PaddingBottom);
  padding-left: var(--pf-c-login__main-body--PaddingLeft);
}

.pf-c-login__main-footer {
  display: flex;
  flex-wrap: wrap;
}
.pf-c-login__main-footer .pf-c-title {
  margin-bottom: var(--pf-c-login__main-footer--c-title--MarginBottom);
  text-align: center;
}
.pf-c-login__main-footer > * {
  flex-basis: 100%;
}

.pf-c-login__main-footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--pf-c-login__main-footer-links--PaddingTop) var(--pf-c-login__main-footer-links--PaddingRight) var(--pf-c-login__main-footer-links--PaddingBottom) var(--pf-c-login__main-footer-links--PaddingLeft);
}

.pf-c-login__main-footer-links-item {
  padding-right: var(--pf-c-login__main-footer-links-item--PaddingRight);
  padding-left: var(--pf-c-login__main-footer-links-item--PaddingLeft);
  margin-bottom: var(--pf-c-login__main-footer-links-item--MarginBottom);
}

.pf-c-login__main-footer-links-item-link svg {
  fill: var(--pf-c-login__main-footer-links-item-link-svg--Fill);
  width: 100%;
  max-width: var(--pf-c-login__main-footer-links-item-link-svg--Width);
  height: 100%;
  max-height: var(--pf-c-login__main-footer-links-item-link-svg--Height);
}
.pf-c-login__main-footer-links-item-link:hover svg {
  fill: var(--pf-c-login__main-footer-links-item-link-svg--hover--Fill);
}

.pf-c-login__main-footer-band {
  padding: var(--pf-c-login__main-footer-band--PaddingTop) var(--pf-c-login__main-footer-band--PaddingRight) var(--pf-c-login__main-footer-band--PaddingBottom) var(--pf-c-login__main-footer-band--PaddingLeft);
  text-align: center;
  background-color: var(--pf-c-login__main-footer-band--BackgroundColor);
}
.pf-c-login__main-footer-band > * + * {
  padding-top: var(--pf-c-login__main-footer-band-item--PaddingTop);
}

.pf-c-login__footer {
  color: var(--pf-global--Color--100);
  grid-area: footer;
  padding-right: var(--pf-c-login__footer--PaddingRight);
  padding-left: var(--pf-c-login__footer--PaddingLeft);
}
.pf-c-login__footer .pf-c-list a {
  color: unset;
}
.pf-c-login__footer .pf-c-list:not(:only-child) {
  padding-top: var(--pf-c-login__footer--c-list--PaddingTop);
}