.pf-c-nav {
  --pf-c-nav--Transition: var(--pf-global--Transition);
  --pf-c-nav--m-light__item--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-nav--m-light__link--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-light__link--hover--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-light__link--focus--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-light__link--active--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-light__link--m-current--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-light__link--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-nav--m-light__link--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-nav--m-light__link--active--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-nav--m-light__link--m-current--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-nav--m-light__link--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-nav--m-light__link--after--BorderColor: var(--pf-global--active-color--100);
  --pf-c-nav--m-light__link--m-current--after--BorderColor: var(--pf-global--active-color--100);
  --pf-c-nav--m-light__section-title--Color: var(--pf-global--Color--dark-200);
  --pf-c-nav--m-light__section-title--BorderBottomColor: var(--pf-global--BorderColor--300);
  --pf-c-nav--m-light--c-divider--BackgroundColor: var(--pf-global--BorderColor--300);
  --pf-c-nav--m-light__subnav__link--hover--after--BorderColor: var(--pf-global--BorderColor--dark-100);
  --pf-c-nav--m-light__subnav__link--focus--after--BorderColor: var(--pf-global--BorderColor--dark-100);
  --pf-c-nav--m-light__subnav__link--active--after--BorderColor: var(--pf-global--BorderColor--dark-100);
  --pf-c-nav--m-light__subnav__link--m-current--after--BorderColor: var(--pf-global--active-color--100);
  --pf-c-nav__item--MarginTop: 0;
  --pf-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-nav__link--m-current--not--m-expanded__link--after--BorderWidth: var(--pf-global--BorderWidth--xl);
  --pf-c-nav__item__item__link--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-nav__item__item__toggle--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav__item__toggle-icon--Rotate: 0;
  --pf-c-nav__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-nav__item--before--BorderColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__item--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__link--FontSize: var(--pf-global--FontSize--md);
  --pf-c-nav__link--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-nav__link--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-nav__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav__link--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-nav__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav__link--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__link--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__link--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__link--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__link--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__link--active--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__link--m-current--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__link--BackgroundColor: transparent;
  --pf-c-nav__link--hover--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__link--focus--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__link--active--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__link--m-current--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-nav__link--OutlineOffset: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-nav__link--before--BorderColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__link--hover--before--BorderBottomWidth: 0;
  --pf-c-nav__link--focus--before--BorderBottomWidth: 0;
  --pf-c-nav__link--active--before--BorderBottomWidth: 0;
  --pf-c-nav__link--m-current--before--BorderBottomWidth: 0;
  --pf-c-nav__link--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__link--hover--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__link--focus--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__link--active--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__link--m-current--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__link--after--BorderLeftWidth: 0;
  --pf-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-c-nav__link--focus--after--BorderLeftWidth: 0;
  --pf-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-global--BorderWidth--xl);
  --pf-c-nav--m-horizontal__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav--m-horizontal__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav--m-horizontal__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal__link--lg--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-nav--m-horizontal__link--lg--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-nav--m-horizontal__link--Right: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal__link--Left: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal__link--Color: var(--pf-global--Color--light-300);
  --pf-c-nav--m-horizontal__link--hover--Color: var(--pf-global--active-color--400);
  --pf-c-nav--m-horizontal__link--focus--Color: var(--pf-global--active-color--400);
  --pf-c-nav--m-horizontal__link--active--Color: var(--pf-global--active-color--400);
  --pf-c-nav--m-horizontal__link--m-current--Color: var(--pf-global--active-color--400);
  --pf-c-nav--m-horizontal__link--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal__link--hover--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal__link--focus--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal__link--active--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal__link--m-current--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal__link--before--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav--m-horizontal__link--before--BorderWidth: 0;
  --pf-c-nav--m-horizontal__link--hover--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-horizontal__link--focus--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-horizontal__link--active--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-horizontal__link--m-current--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-tertiary__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav--m-tertiary__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav--m-tertiary__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav--m-tertiary__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav--m-tertiary__link--Right: var(--pf-global--spacer--md);
  --pf-c-nav--m-tertiary__link--Left: var(--pf-global--spacer--md);
  --pf-c-nav--m-tertiary__link--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-tertiary__link--hover--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__link--focus--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__link--active--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__link--m-current--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__link--BackgroundColor: transparent;
  --pf-c-nav--m-tertiary__link--hover--BackgroundColor: transparent;
  --pf-c-nav--m-tertiary__link--focus--BackgroundColor: transparent;
  --pf-c-nav--m-tertiary__link--active--BackgroundColor: transparent;
  --pf-c-nav--m-tertiary__link--m-current--BackgroundColor: transparent;
  --pf-c-nav--m-tertiary__link--before--BorderColor: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__link--before--BorderWidth: 0;
  --pf-c-nav--m-tertiary__link--hover--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-tertiary__link--focus--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-tertiary__link--active--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-tertiary__link--m-current--before--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-nav--m-tertiary__scroll-button--Color: var(--pf-global--Color--dark-100);
  --pf-c-nav--m-tertiary__scroll-button--hover--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__scroll-button--focus--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__scroll-button--active--Color: var(--pf-global--active-color--100);
  --pf-c-nav--m-tertiary__scroll-button--disabled--Color: var(--pf-global--disabled-color--200);
  --pf-c-nav--m-tertiary__scroll-button--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor: var(--pf-global--disabled-color--300);
  --pf-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal-subnav__link--xl--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal-subnav__link--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav--m-horizontal-subnav__link--xl--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-nav--m-horizontal-subnav__link--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav--m-horizontal-subnav__link--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav--m-horizontal-subnav__link--Color: var(--pf-global--Color--light-200);
  --pf-c-nav--m-horizontal-subnav__link--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--m-horizontal-subnav__link--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--m-horizontal-subnav__link--active--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--m-horizontal-subnav__link--m-current--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--m-horizontal-subnav__link--BackgroundColor: transparent;
  --pf-c-nav--m-horizontal-subnav__link--hover--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--m-horizontal-subnav__link--focus--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--m-horizontal-subnav__link--active--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-nav--m-horizontal-subnav__link--before--BorderColor: transparent;
  --pf-c-nav--m-horizontal-subnav__link--after--BorderColor: var(--pf-global--palette--black-800);
  --pf-c-nav--m-horizontal-subnav__link--hover--after--BorderColor: var(--pf-global--palette--black-800);
  --pf-c-nav--m-horizontal-subnav__link--active--after--BorderColor: var(--pf-global--palette--black-800);
  --pf-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor: var(--pf-global--palette--black-800);
  --pf-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__subnav--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-nav__subnav--xl--PaddingLeft: var(--pf-c-nav__link--PaddingLeft);
  --pf-c-nav__subnav__link--MarginTop: 0;
  --pf-c-nav__subnav__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav__subnav__link--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__subnav__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav__subnav__link--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__subnav__link--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav__subnav__link--hover--after--BorderColor: var(--pf-global--BorderColor--200);
  --pf-c-nav__subnav__link--focus--after--BorderColor: var(--pf-global--BorderColor--200);
  --pf-c-nav__subnav__link--active--after--BorderColor: var(--pf-global--BorderColor--200);
  --pf-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__subnav__link--hover--after--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__subnav__link--focus--after--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__subnav__link--active--after--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__subnav__link--m-current--after--BorderWidth: var(--pf-global--BorderWidth--xl);
  --pf-c-nav__subnav--MaxHeight: 0;
  --pf-c-nav__subnav__subnav--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__subnav__subnav__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav__subnav__subnav__link--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-nav__item--m-expanded__subnav--MaxHeight: 100%;
  --pf-c-nav__subnav--c-divider--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__subnav--c-divider--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__section--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-nav__section__item--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-nav__section__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav__section__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav__section__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav__section__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav__section__link--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__section__link--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__section__link--FontSize: var(--pf-global--FontSize--md);
  --pf-c-nav__section__link--before--BorderBottomWidth: 0;
  --pf-c-nav__section__link--hover--after--BorderColor: transparent;
  --pf-c-nav__section__link--focus--after--BorderColor: transparent;
  --pf-c-nav__section__link--active--after--BorderColor: transparent;
  --pf-c-nav__section__link--m-current--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav__section__link--hover--after--BorderWidth: 0;
  --pf-c-nav__section__link--focus--after--BorderWidth: 0;
  --pf-c-nav__section__link--active--after--BorderWidth: 0;
  --pf-c-nav__section__link--m-current--after--BorderWidth: var(--pf-global--BorderWidth--xl);
  --pf-c-nav__section--section--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-nav__section-title--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav__section-title--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav__section-title--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav__section-title--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav__section-title--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__section-title--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__section-title--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav__section-title--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__section-title--BorderBottomColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__section-title--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__scroll-button--Color: var(--pf-global--Color--light-100);
  --pf-c-nav__scroll-button--hover--Color: var(--pf-global--active-color--400);
  --pf-c-nav__scroll-button--focus--Color: var(--pf-global--active-color--400);
  --pf-c-nav__scroll-button--active--Color: var(--pf-global--active-color--400);
  --pf-c-nav__scroll-button--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-nav__scroll-button--BackgroundColor: transparent;
  --pf-c-nav__scroll-button--Width: var(--pf-global--target-size--MinWidth);
  --pf-c-nav__scroll-button--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
  --pf-c-nav__scroll-button--Transition: margin .125s, transform .125s, opacity .125s;
  --pf-c-nav__scroll-button--before--BorderColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__scroll-button--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__scroll-button--before--BorderRightWidth: 0;
  --pf-c-nav__scroll-button--before--BorderLeftWidth: 0;
  --pf-c-nav__scroll-button--disabled--before--BorderColor: transparent;
  --pf-c-nav__toggle--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-nav__toggle--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-nav__toggle--FontSize: var(--pf-global--icon--FontSize--md);
  --pf-c-nav__toggle-icon--Transition: var(--pf-global--TransitionDuration);
  --pf-c-nav--c-divider--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-nav--c-divider--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-nav--c-divider--PaddingRight: 0;
  --pf-c-nav--c-divider--PaddingLeft: 0;
  --pf-c-nav--c-divider--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__list--ScrollSnapTypeAxis: x;
  --pf-c-nav__list--ScrollSnapTypeStrictness: proximity;
  --pf-c-nav__list--ScrollSnapType: var(--pf-c-nav__list--ScrollSnapTypeAxis) var(--pf-c-nav__list--ScrollSnapTypeStrictness);
  --pf-c-nav__item--ScrollSnapAlign: end;
  --pf-c-nav__item--m-flyout--c-menu--top-offset: 0px;
  --pf-c-nav__item--m-flyout--c-menu--left-offset: 0.25rem;
  --pf-c-nav__item--m-flyout--c-menu--m-top--bottom-offset: 0px;
  --pf-c-nav__item--m-flyout--c-menu--m-left--right-offset: 0.25rem;
  --pf-c-nav__item--m-flyout--MarginTop: 0;
  --pf-c-nav__item--m-flyout--hover__link--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__item--m-flyout--focus__link--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__item--m-flyout--active__link--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav__item--m-flyout--hover__link--before--BorderWidth: 0;
  --pf-c-nav__item--m-flyout--focus__link--before--BorderWidth: 0;
  --pf-c-nav__item--m-flyout--active__link--before--BorderWidth: 0;
  --pf-c-nav__item--m-flyout__item--last-child__link--before--BorderBottomWidth: 0;
  --pf-c-nav__item--m-flyout--c-menu--Top: calc(0px + var(--pf-c-nav__item--m-flyout--c-menu--top-offset));
  --pf-c-nav__item--m-flyout--c-menu--c-menu--Top: calc(var(--pf-c-nav__item--m-flyout--c-menu--Top) - var(--pf-c-nav__item--before--BorderWidth));
  --pf-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top: 0;
  --pf-c-nav__item--m-flyout--c-menu--Left: calc(100% - var(--pf-c-nav__item--m-flyout--c-menu--left-offset));
  --pf-c-nav__item--m-flyout--c-menu--m-left--Right: calc(100% - var(--pf-c-nav__item--m-flyout--c-menu--m-left--right-offset));
  --pf-c-nav__item--m-flyout--c-menu--m-top--Bottom: calc(0 + var(--pf-c-nav__item--m-flyout--c-menu--m-top--bottom-offset));
  --pf-c-nav__item--m-flyout--c-menu--BackgroundColor: var(--pf-global--BackgroundColor--dark-300);
  --pf-c-nav__item--m-flyout--c-menu__item--Color: var(--pf-c-nav__link--Color);
  --pf-c-nav__item--m-flyout--c-menu__item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav__item--m-flyout--c-menu__item--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav__item--m-flyout--c-menu__item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav__item--m-flyout--c-menu__item--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav__item--m-flyout--c-menu__item-description--Color: var(--pf-global--Color--light-200);
  --pf-c-nav__item--m-flyout--c-menu__item--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav__item--m-flyout--c-menu__item--OutlineOffset: var(--pf-c-nav__link--OutlineOffset);
  --pf-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth: var(--pf-c-nav__item--before--BorderWidth);
  --pf-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor: var(--pf-c-nav__item--before--BorderColor);
  --pf-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor: var(--pf-global--BorderColor--200);
  --pf-c-nav__item--m-flyout--c-menu__item--hover--after--Top: calc(-1 * var(--pf-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth));
  --pf-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor: var(--pf-c-nav__link--hover--BackgroundColor);
  --pf-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor: var(--pf-c-nav__link--focus--BackgroundColor);
  --pf-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor: var(--pf-c-nav__link--active--BackgroundColor);
  --pf-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top: 0;
  --pf-c-nav__item--m-flyout--c-menu--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-nav--c-menu--BackgroundColor: var(--pf-global--BackgroundColor--dark-300);
  --pf-c-nav--c-menu__list--PaddingTop: 0;
  --pf-c-nav--c-menu__list--PaddingBottom: 0;
  --pf-c-nav--c-menu__list-item--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--c-menu__list-item--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--c-menu__list-item--active--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--c-menu__list-item--focus-within--Color: var(--pf-global--Color--light-100);
  --pf-c-nav--c-menu__list-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--c-menu__list-item--focus-within--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--c-menu__item--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-nav--c-menu__item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-nav--c-menu__item--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-nav--c-menu__item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-nav--c-menu__item--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav--c-menu__item--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav--c-menu__item--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-nav--c-menu__item--OutlineOffset: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-nav--c-menu__item--before--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav--c-menu__item--before--BorderBottomColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-nav--c-menu__item--after--BorderLeftWidth: 0;
  --pf-c-nav--c-menu__item--after--BorderLeftColor: transparent;
  --pf-c-nav--c-menu__item--m-current--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-nav--c-menu__item--m-current--after--BorderLeftWidth: var(--pf-global--BorderWidth--xl);
  --pf-c-nav--c-menu__item--m-current--after--BorderColor: var(--pf-global--active-color--400);
  --pf-c-nav--c-menu__item-description--Color: var(--pf-global--Color--400);
  --pf-c-nav--c-menu--m-flyout--left-offset: 0.25rem;
  --pf-c-nav--c-menu--m-flyout--m-left--right-offset: 0.25rem;
  --pf-c-nav--c-menu--m-flyout--Top: 0;
  --pf-c-nav--c-menu--m-flyout--c-menu--Top: calc(var(--pf-c-nav--c-menu--m-flyout--Top) * -1);
  --pf-c-nav--c-menu--m-flyout--c-menu--Right: auto;
  --pf-c-nav--c-menu--m-flyout--c-menu--Bottom: auto;
  --pf-c-nav--c-menu--m-flyout--c-menu--Left: calc(100% - var(--pf-c-nav--c-menu--m-flyout--left-offset));
  --pf-c-nav--c-menu--m-flyout--m-left--Right: calc(100% - var(--pf-c-nav--c-menu--m-flyout--m-left--right-offset));
  --pf-c-nav--c-menu--m-flyout--m-top--Bottom: 0;
  --pf-c-nav--c-menu--m-flyout--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-nav--c-menu--m-flyout__item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-nav--c-menu--m-flyout__item--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-nav--c-menu--m-flyout__item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-nav--c-menu--m-flyout__item--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor: var(--pf-global--BorderColor--200);
  --pf-c-nav__toggle--m-start--MarginRight: var(--pf-global--spacer--sm);
  position: relative;
}
@media screen and (min-width: 1200px) {
  .pf-c-nav {
    --pf-c-nav__link--PaddingRight: var(--pf-c-nav__link--xl--PaddingRight);
    --pf-c-nav__link--PaddingLeft: var(--pf-c-nav__link--xl--PaddingLeft);
    --pf-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-c-nav--m-horizontal-subnav__link--xl--PaddingTop);
    --pf-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-c-nav--m-horizontal-subnav__link--xl--PaddingRight);
    --pf-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-c-nav--m-horizontal-subnav__link--xl--PaddingBottom);
    --pf-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-c-nav--m-horizontal-subnav__link--xl--PaddingLeft);
    --pf-c-nav__section__link--PaddingRight: var(--pf-c-nav__section__link--xl--PaddingRight);
    --pf-c-nav__section__link--PaddingLeft: var(--pf-c-nav__section__link--xl--PaddingLeft);
    --pf-c-nav__section-title--PaddingRight: var(--pf-c-nav__section-title--xl--PaddingRight);
    --pf-c-nav__section-title--PaddingLeft: var(--pf-c-nav__section-title--xl--PaddingLeft);
    --pf-c-nav__subnav--PaddingLeft: var(--pf-c-nav__subnav--xl--PaddingLeft);
    --pf-c-nav--c-menu__item--PaddingRight: var(--pf-c-nav--c-menu__item--xl--PaddingRight);
    --pf-c-nav--c-menu__item--PaddingLeft: var(--pf-c-nav--c-menu__item--xl--PaddingLeft);
  }
}
.pf-c-nav .pf-c-menu {
  --pf-c-menu--MinWidth: 100%;
  --pf-c-menu--BackgroundColor: var(--pf-c-nav--c-menu--BackgroundColor);
  --pf-c-menu__list--PaddingTop: var(--pf-c-nav--c-menu__list--PaddingTop);
  --pf-c-menu__list--PaddingBottom: var(--pf-c-nav--c-menu__list--PaddingBottom);
  --pf-c-menu__item--PaddingTop: var(--pf-c-nav--c-menu__item--PaddingTop);
  --pf-c-menu__item--PaddingRight: var(--pf-c-nav--c-menu__item--PaddingRight);
  --pf-c-menu__item--PaddingBottom: var(--pf-c-nav--c-menu__item--PaddingBottom);
  --pf-c-menu__item--PaddingLeft: var(--pf-c-nav--c-menu__item--PaddingLeft);
  --pf-c-menu__list-item--Color: var(--pf-c-nav--c-menu__list-item--Color);
  --pf-c-menu__list-item--hover--Color: var(--pf-c-nav--c-menu__list-item--hover--Color);
  --pf-c-menu__list-item--active--Color: var(--pf-c-nav--c-menu__list-item--active--Color);
  --pf-c-menu__list-item--focus-within--Color: var(--pf-c-nav--c-menu__list-item--focus-within--Color);
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-c-nav--c-menu__list-item--hover--BackgroundColor);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-c-nav--c-menu__list-item--focus-within--BackgroundColor);
  --pf-c-menu__item-description--Color: var(--pf-c-nav--c-menu__item-description--Color);
}
.pf-c-nav .pf-c-menu:first-child > .pf-c-menu__content {
  border-bottom: var(--pf-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-c-nav--c-menu__item--before--BorderBottomColor);
}
.pf-c-nav .pf-c-menu.pf-m-flyout, .pf-c-nav .pf-c-menu.pf-m-flyout .pf-c-menu {
  --pf-c-menu--BoxShadow: var(--pf-c-nav--c-menu--m-flyout--BoxShadow);
  --pf-c-menu__list--PaddingTop: 0;
  --pf-c-menu__list--PaddingBottom: 0;
  --pf-c-menu__item--FontSize: var(--pf-c-nav--c-menu__item--FontSize);
  --pf-c-menu__item--Color: var(--pf-c-nav--c-menu__item--Color);
  --pf-c-menu__item--PaddingTop: var(--pf-c-nav--c-menu--m-flyout__item--PaddingTop);
  --pf-c-menu__item--PaddingRight: var(--pf-c-nav--c-menu--m-flyout__item--PaddingRight);
  --pf-c-menu__item--PaddingBottom: var(--pf-c-nav--c-menu--m-flyout__item--PaddingBottom);
  --pf-c-menu__item--PaddingLeft: var(--pf-c-nav--c-menu--m-flyout__item--PaddingLeft);
  top: var(--pf-c-nav--c-menu--m-flyout--c-menu--Top);
  right: var(--pf-c-nav--c-menu--m-flyout--c-menu--Right);
  bottom: var(--pf-c-nav--c-menu--m-flyout--c-menu--Bottom);
  left: var(--pf-c-nav--c-menu--m-flyout--c-menu--Left);
}
.pf-c-nav .pf-c-menu.pf-m-flyout .pf-c-menu__item:hover {
  --pf-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth);
  --pf-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor);
}
.pf-c-nav .pf-c-menu.pf-m-top {
  --pf-c-nav--c-menu--m-flyout--c-menu--Top: auto;
  --pf-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-c-nav--c-menu--m-flyout--m-top--Bottom);
}
.pf-c-nav .pf-c-menu.pf-m-left {
  --pf-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-c-nav--c-menu--m-flyout--m-left--Right);
  --pf-c-nav--c-menu--m-flyout--c-menu--Left: auto;
}
.pf-c-nav .pf-c-menu .pf-c-divider {
  margin-top: 0;
  margin-bottom: 0;
}
.pf-c-nav .pf-c-menu__item {
  position: relative;
  outline-offset: var(--pf-c-nav--c-menu__item--OutlineOffset);
}
.pf-c-nav .pf-c-menu__item::before, .pf-c-nav .pf-c-menu__item::after {
  position: absolute;
  top: 0;
  content: "";
}
.pf-c-nav .pf-c-menu__item::before {
  right: 0;
  bottom: calc(var(--pf-c-nav--c-menu__item--before--BorderBottomWidth) * -1);
  left: 0;
  border-bottom: var(--pf-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-c-nav--c-menu__item--before--BorderBottomColor);
}
.pf-c-nav .pf-c-menu__item::after {
  bottom: 0;
  left: 0;
  border-left: var(--pf-c-nav--c-menu__item--after--BorderLeftWidth) solid var(--pf-c-nav--c-menu__item--after--BorderLeftColor);
}
.pf-c-nav .pf-c-menu__item.pf-m-current {
  --pf-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-c-nav--c-menu__item--m-current--after--BorderLeftWidth);
  --pf-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-c-nav--c-menu__item--m-current--after--BorderColor);
  background-color: var(--pf-c-nav--c-menu__item--m-current--BackgroundColor);
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__link::after, .pf-c-nav.pf-m-tertiary .pf-c-nav__link::after {
  content: none;
}
.pf-c-nav.pf-m-horizontal, .pf-c-nav.pf-m-tertiary, .pf-c-nav.pf-m-horizontal-subnav {
  overflow: hidden;
}
.pf-c-nav.pf-m-horizontal,
.pf-c-nav.pf-m-horizontal .pf-c-nav__list, .pf-c-nav.pf-m-tertiary,
.pf-c-nav.pf-m-tertiary .pf-c-nav__list, .pf-c-nav.pf-m-horizontal-subnav,
.pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__list {
  position: relative;
  display: flex;
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__list, .pf-c-nav.pf-m-tertiary .pf-c-nav__list, .pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__list {
  flex: 1;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: var(--pf-c-nav__list--ScrollSnapType);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__list::-webkit-scrollbar, .pf-c-nav.pf-m-tertiary .pf-c-nav__list::-webkit-scrollbar, .pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__list::-webkit-scrollbar {
  display: none;
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__item, .pf-c-nav.pf-m-tertiary .pf-c-nav__item, .pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__item {
  display: flex;
  scroll-snap-align: var(--pf-c-nav__item--ScrollSnapAlign);
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__link, .pf-c-nav.pf-m-tertiary .pf-c-nav__link, .pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__link {
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__link::before, .pf-c-nav.pf-m-tertiary .pf-c-nav__link::before, .pf-c-nav.pf-m-horizontal-subnav .pf-c-nav__link::before {
  top: auto;
  bottom: 0;
}
.pf-c-nav.pf-m-horizontal .pf-c-nav__link::before {
  right: var(--pf-c-nav--m-horizontal__link--Right);
  left: var(--pf-c-nav--m-horizontal__link--Left);
}
.pf-c-nav.pf-m-tertiary .pf-c-nav__link::before {
  right: var(--pf-c-nav--m-tertiary__link--Right);
  left: var(--pf-c-nav--m-tertiary__link--Left);
}
.pf-c-nav.pf-m-light {
  --pf-c-nav__item--before--BorderColor: var(--pf-c-nav--m-light__item--before--BorderColor);
  --pf-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor);
  --pf-c-nav__link--Color: var(--pf-c-nav--m-light__link--Color);
  --pf-c-nav__link--hover--Color: var(--pf-c-nav--m-light__link--hover--Color);
  --pf-c-nav__link--focus--Color: var(--pf-c-nav--m-light__link--focus--Color);
  --pf-c-nav__link--active--Color: var(--pf-c-nav--m-light__link--active--Color);
  --pf-c-nav__link--m-current--Color: var(--pf-c-nav--m-light__link--m-current--Color);
  --pf-c-nav__link--hover--BackgroundColor: var(--pf-c-nav--m-light__link--hover--BackgroundColor);
  --pf-c-nav__link--focus--BackgroundColor: var(--pf-c-nav--m-light__link--focus--BackgroundColor);
  --pf-c-nav__link--active--BackgroundColor: var(--pf-c-nav--m-light__link--active--BackgroundColor);
  --pf-c-nav__link--m-current--BackgroundColor: var(--pf-c-nav--m-light__link--m-current--BackgroundColor);
  --pf-c-nav__link--before--BorderColor: var(--pf-c-nav--m-light__link--before--BorderColor);
  --pf-c-nav__link--after--BorderColor: var(--pf-c-nav--m-light__link--after--BorderColor);
  --pf-c-nav__link--m-current--after--BorderColor: var(--pf-c-nav--m-light__link--m-current--after--BorderColor);
  --pf-c-nav__subnav__link--hover--after--BorderColor: var(--pf-c-nav--m-light__subnav__link--hover--after--BorderColor);
  --pf-c-nav__subnav__link--focus--after--BorderColor: var(--pf-c-nav--m-light__subnav__link--focus--after--BorderColor);
  --pf-c-nav__subnav__link--active--after--BorderColor: var(--pf-c-nav--m-light__subnav__link--active--after--BorderColor);
  --pf-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-c-nav--m-light__subnav__link--m-current--after--BorderColor);
  --pf-c-nav__section-title--Color: var(--pf-c-nav--m-light__section-title--Color);
  --pf-c-nav__section-title--BorderBottomColor: var(--pf-c-nav--m-light__section-title--BorderBottomColor);
}
.pf-c-nav.pf-m-light .pf-c-divider {
  --pf-c-divider--after--BackgroundColor: var(--pf-c-nav--m-light--c-divider--BackgroundColor);
}
.pf-c-nav.pf-m-horizontal {
  --pf-c-nav__link--PaddingTop: var(--pf-c-nav--m-horizontal__link--PaddingTop);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav--m-horizontal__link--PaddingRight);
  --pf-c-nav__link--PaddingBottom: var(--pf-c-nav--m-horizontal__link--PaddingBottom);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav--m-horizontal__link--PaddingLeft);
  --pf-c-nav__link--Right: var(--pf-c-nav--m-horizontal__link--Right);
  --pf-c-nav__link--Left: var(--pf-c-nav--m-horizontal__link--Left);
  --pf-c-nav__link--Color: var(--pf-c-nav--m-horizontal__link--Color);
  --pf-c-nav__link--hover--Color: var(--pf-c-nav--m-horizontal__link--hover--Color);
  --pf-c-nav__link--active--Color: var(--pf-c-nav--m-horizontal__link--active--Color);
  --pf-c-nav__link--focus--Color: var(--pf-c-nav--m-horizontal__link--focus--Color);
  --pf-c-nav__link--m-current--Color: var(--pf-c-nav--m-horizontal__link--m-current--Color);
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav--m-horizontal__link--BackgroundColor);
  --pf-c-nav__link--hover--BackgroundColor: var(--pf-c-nav--m-horizontal__link--hover--BackgroundColor);
  --pf-c-nav__link--focus--BackgroundColor: var(--pf-c-nav--m-horizontal__link--focus--BackgroundColor);
  --pf-c-nav__link--active--BackgroundColor: var(--pf-c-nav--m-horizontal__link--active--BackgroundColor);
  --pf-c-nav__link--m-current--BackgroundColor: var(--pf-c-nav--m-horizontal__link--m-current--BackgroundColor);
  --pf-c-nav__link--before--BorderColor: var(--pf-c-nav--m-horizontal__link--before--BorderColor);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav--m-horizontal__link--before--BorderWidth);
  --pf-c-nav__link--hover--before--BorderBottomWidth: var(--pf-c-nav--m-horizontal__link--hover--before--BorderWidth);
  --pf-c-nav__link--focus--before--BorderBottomWidth: var(--pf-c-nav--m-horizontal__link--focus--before--BorderWidth);
  --pf-c-nav__link--active--before--BorderBottomWidth: var(--pf-c-nav--m-horizontal__link--active--before--BorderWidth);
  --pf-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-c-nav--m-horizontal__link--m-current--before--BorderWidth);
}
.pf-c-nav.pf-m-tertiary {
  --pf-c-nav__link--PaddingTop: var(--pf-c-nav--m-tertiary__link--PaddingTop);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav--m-tertiary__link--PaddingRight);
  --pf-c-nav__link--PaddingBottom: var(--pf-c-nav--m-tertiary__link--PaddingBottom);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav--m-tertiary__link--PaddingLeft);
  --pf-c-nav__link--Right: var(--pf-c-nav--m-tertiary__link--Right);
  --pf-c-nav__link--Left: var(--pf-c-nav--m-tertiary__link--Left);
  --pf-c-nav__link--Color: var(--pf-c-nav--m-tertiary__link--Color);
  --pf-c-nav__link--hover--Color: var(--pf-c-nav--m-tertiary__link--hover--Color);
  --pf-c-nav__link--active--Color: var(--pf-c-nav--m-tertiary__link--active--Color);
  --pf-c-nav__link--focus--Color: var(--pf-c-nav--m-tertiary__link--focus--Color);
  --pf-c-nav__link--m-current--Color: var(--pf-c-nav--m-tertiary__link--m-current--Color);
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav--m-tertiary__link--BackgroundColor);
  --pf-c-nav__link--hover--BackgroundColor: var(--pf-c-nav--m-tertiary__link--hover--BackgroundColor);
  --pf-c-nav__link--focus--BackgroundColor: var(--pf-c-nav--m-tertiary__link--focus--BackgroundColor);
  --pf-c-nav__link--active--BackgroundColor: var(--pf-c-nav--m-tertiary__link--active--BackgroundColor);
  --pf-c-nav__link--m-current--BackgroundColor: var(--pf-c-nav--m-tertiary__link--m-current--BackgroundColor);
  --pf-c-nav__link--before--BorderColor: var(--pf-c-nav--m-tertiary__link--before--BorderColor);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav--m-tertiary__link--before--BorderWidth);
  --pf-c-nav__link--hover--before--BorderBottomWidth: var(--pf-c-nav--m-tertiary__link--hover--before--BorderWidth);
  --pf-c-nav__link--focus--before--BorderBottomWidth: var(--pf-c-nav--m-tertiary__link--focus--before--BorderWidth);
  --pf-c-nav__link--active--before--BorderBottomWidth: var(--pf-c-nav--m-tertiary__link--active--before--BorderWidth);
  --pf-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-c-nav--m-tertiary__link--m-current--before--BorderWidth);
  --pf-c-nav__scroll-button--Color: var(--pf-c-nav--m-tertiary__scroll-button--Color);
  --pf-c-nav__scroll-button--hover--Color: var(--pf-c-nav--m-tertiary__scroll-button--hover--Color);
  --pf-c-nav__scroll-button--focus--Color: var(--pf-c-nav--m-tertiary__scroll-button--focus--Color);
  --pf-c-nav__scroll-button--active--Color: var(--pf-c-nav--m-tertiary__scroll-button--active--Color);
  --pf-c-nav__scroll-button--disabled--Color: var(--pf-c-nav--m-tertiary__scroll-button--disabled--Color);
  --pf-c-nav__scroll-button--before--BorderColor: var(--pf-c-nav--m-tertiary__scroll-button--before--BorderColor);
  --pf-c-nav__scroll-button--disabled--before--BorderColor: var(--pf-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor);
}
.pf-c-nav.pf-m-horizontal-subnav {
  --pf-c-nav__link--FontSize: var(--pf-c-nav--m-horizontal-subnav__link--FontSize);
  --pf-c-nav__link--PaddingTop: var(--pf-c-nav--m-horizontal-subnav__link--PaddingTop);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav--m-horizontal-subnav__link--PaddingRight);
  --pf-c-nav__link--PaddingBottom: var(--pf-c-nav--m-horizontal-subnav__link--PaddingBottom);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav--m-horizontal-subnav__link--PaddingLeft);
  --pf-c-nav__link--Right: var(--pf-c-nav--m-horizontal-subnav__link--Right);
  --pf-c-nav__link--Left: var(--pf-c-nav--m-horizontal-subnav__link--Left);
  --pf-c-nav__link--Color: var(--pf-c-nav--m-horizontal-subnav__link--Color);
  --pf-c-nav__link--hover--Color: var(--pf-c-nav--m-horizontal-subnav__link--hover--Color);
  --pf-c-nav__link--active--Color: var(--pf-c-nav--m-horizontal-subnav__link--active--Color);
  --pf-c-nav__link--focus--Color: var(--pf-c-nav--m-horizontal-subnav__link--focus--Color);
  --pf-c-nav__link--m-current--Color: var(--pf-c-nav--m-horizontal-subnav__link--m-current--Color);
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav--m-horizontal-subnav__link--BackgroundColor);
  --pf-c-nav__link--hover--BackgroundColor: var(--pf-c-nav--m-horizontal-subnav__link--hover--BackgroundColor);
  --pf-c-nav__link--focus--BackgroundColor: var(--pf-c-nav--m-horizontal-subnav__link--focus--BackgroundColor);
  --pf-c-nav__link--active--BackgroundColor: var(--pf-c-nav--m-horizontal-subnav__link--active--BackgroundColor);
  --pf-c-nav__link--m-current--BackgroundColor: var(--pf-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor);
  --pf-c-nav__link--before--BorderColor: var(--pf-c-nav--m-horizontal-subnav__link--before--BorderColor);
  --pf-c-nav__link--after--BorderColor: var(--pf-c-nav--m-horizontal-subnav__link--after--BorderColor);
  --pf-c-nav__link--hover--after--BorderColor: var(--pf-c-nav--m-horizontal-subnav__link--hover--after--BorderColor);
  --pf-c-nav__link--active--after--BorderColor: var(--pf-c-nav--m-horizontal-subnav__link--active--after--BorderColor);
  --pf-c-nav__link--m-current--after--BorderColor: var(--pf-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor);
  --pf-c-nav__link--after--BorderLeftWidth: var(--pf-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth);
  --pf-c-nav__link--hover--after--BorderLeftWidth: var(--pf-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth);
  --pf-c-nav__link--active--after--BorderLeftWidth: var(--pf-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth);
  --pf-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth);
}
.pf-c-nav.pf-m-horizontal-subnav.pf-m-scrollable .pf-c-nav__item:first-child {
  --pf-c-nav__link--after--BorderLeftWidth: 0;
  --pf-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-c-nav__link--m-current--after--BorderLeftWidth: 0;
}
.pf-c-nav .pf-c-divider {
  --pf-c-divider--after--BackgroundColor: var(--pf-c-nav--c-divider--BackgroundColor);
  padding-right: var(--pf-c-nav--c-divider--PaddingRight);
  padding-left: var(--pf-c-nav--c-divider--PaddingLeft);
  margin-top: var(--pf-c-nav--c-divider--MarginTop);
  margin-bottom: var(--pf-c-nav--c-divider--MarginBottom);
}
.pf-c-nav.pf-m-scrollable .pf-c-nav__scroll-button {
  opacity: 1;
}
.pf-c-nav.pf-m-scrollable .pf-c-nav__scroll-button:nth-of-type(1) {
  margin-right: 0;
  transform: translateX(0);
}
.pf-c-nav.pf-m-scrollable .pf-c-nav__scroll-button:nth-of-type(2) {
  margin-left: 0;
  transform: translateX(0);
}
.pf-c-nav.pf-m-overflow-hidden {
  overflow: hidden;
}

.pf-c-nav__list {
  position: relative;
  display: block;
}

.pf-c-nav__item {
  position: relative;
  margin-top: var(--pf-c-nav__item--MarginTop);
}
.pf-c-nav__item.pf-m-expandable {
  --pf-c-nav__link--before--BorderBottomWidth: 0;
}
.pf-c-nav__item.pf-m-expandable::before {
  position: absolute;
  right: 0;
  bottom: calc(var(--pf-c-nav__item--before--BorderWidth) * -1);
  left: 0;
  content: "";
  border-bottom: var(--pf-c-nav__item--before--BorderWidth) solid var(--pf-c-nav__item--before--BorderColor);
}
.pf-c-nav__item .pf-c-nav__item:not(.pf-m-expanded) .pf-c-nav__toggle-icon {
  transform: rotate(0);
}
.pf-c-nav__item .pf-c-nav__item.pf-m-expandable {
  --pf-c-nav__toggle--FontSize: var(--pf-c-nav__item__item__toggle--FontSize);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav__item__item__link--PaddingRight);
}
@media screen and (min-width: 1200px) {
  .pf-c-nav__item .pf-c-nav__item.pf-m-expandable::before {
    border-bottom: none;
  }
}
.pf-c-nav__item .pf-c-nav__item.pf-m-expandable .pf-c-nav__list::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  content: "";
  border-left: var(--pf-c-nav__item--before--BorderWidth) solid var(--pf-c-nav__item--before--BorderColor);
}
.pf-c-nav__item .pf-c-nav__toggle-icon {
  transform: rotate(var(--pf-c-nav__item__toggle-icon--Rotate));
}
.pf-c-nav__item.pf-m-flyout:hover {
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav__item--m-flyout--hover__link--BackgroundColor);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav__item--m-flyout--hover__link--before--BorderWidth);
}
.pf-c-nav__item.pf-m-flyout:focus {
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav__item--m-flyout--focus__link--BackgroundColor);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav__item--m-flyout--focus__link--before--BorderWidth);
}
.pf-c-nav__item.pf-m-flyout:active {
  --pf-c-nav__link--BackgroundColor: var(--pf-c-nav__item--m-flyout--active__link--BackgroundColor);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav__item--m-flyout--active__link--before--BorderWidth);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu {
  --pf-c-menu--MinWidth: 100%;
  --pf-c-menu--BoxShadow: var(--pf-c-nav__item--m-flyout--c-menu--BoxShadow);
  --pf-c-menu--BackgroundColor: var(--pf-c-nav__item--m-flyout--c-menu--BackgroundColor);
  --pf-c-menu__list--PaddingTop: 0;
  --pf-c-menu__list--PaddingBottom: 0;
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor);
  --pf-c-menu__list-item--active--BackgroundColor: var(--pf-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor);
  --pf-c-menu__item--FontSize: var(--pf-c-nav__item--m-flyout--c-menu__item--FontSize);
  --pf-c-menu__item--Color: var(--pf-c-nav__item--m-flyout--c-menu__item--Color);
  --pf-c-menu__item--PaddingTop: var(--pf-c-nav__item--m-flyout--c-menu__item--PaddingTop);
  --pf-c-menu__item--PaddingRight: var(--pf-c-nav__item--m-flyout--c-menu__item--PaddingRight);
  --pf-c-menu__item--PaddingBottom: var(--pf-c-nav__item--m-flyout--c-menu__item--PaddingBottom);
  --pf-c-menu__item--PaddingLeft: var(--pf-c-nav__item--m-flyout--c-menu__item--PaddingLeft);
  --pf-c-menu__item-description--Color: var(--pf-c-nav__item--m-flyout--c-menu__item-description--Color);
  --pf-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-c-nav__item--m-flyout--c-menu--m-top--Bottom);
  --pf-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-c-nav__item--m-flyout--c-menu--m-left--Right);
  position: absolute;
  top: var(--pf-c-nav__item--m-flyout--c-menu--Top);
  left: var(--pf-c-nav__item--m-flyout--c-menu--Left);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu .pf-c-menu {
  top: var(--pf-c-nav__item--m-flyout--c-menu--c-menu--Top);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu .pf-c-menu__list-item:first-child {
  --pf-c-nav__item--m-flyout--c-menu--c-menu--Top: var(--pf-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top);
  --pf-c-nav__item--m-flyout--c-menu__item--hover--after--Top: var(--pf-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu .pf-c-menu__item {
  position: relative;
  outline-offset: var(--pf-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu .pf-c-menu__item::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-bottom: var(--pf-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth) solid var(--pf-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor);
}
.pf-c-nav__item.pf-m-flyout .pf-c-menu .pf-c-menu__item:hover::after {
  position: absolute;
  top: var(--pf-c-nav__item--m-flyout--c-menu__item--hover--after--Top);
  bottom: 0;
  left: 0;
  content: "";
  border-left: var(--pf-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth) solid var(--pf-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor);
}
.pf-c-nav__item.pf-m-drilldown.pf-m-expanded > .pf-c-nav__subnav {
  left: 0;
  z-index: var(--pf-c-nav__item--m-drilldown--m-expanded__subnav--ZIndex);
}

.pf-c-nav__link {
  position: relative;
  display: flex;
  align-items: baseline;
  padding: var(--pf-c-nav__link--PaddingTop) var(--pf-c-nav__link--PaddingRight) var(--pf-c-nav__link--PaddingBottom) var(--pf-c-nav__link--PaddingLeft);
  font-size: var(--pf-c-nav__link--FontSize);
  font-weight: var(--pf-c-nav__link--FontWeight);
  color: var(--pf-c-nav__link--Color);
  background-color: var(--pf-c-nav__link--BackgroundColor);
  outline-offset: var(--pf-c-nav__link--OutlineOffset);
}
.pf-c-nav__link::after, .pf-c-nav__link::before {
  position: absolute;
  content: "";
  border: 0 solid;
}
.pf-c-nav__link::before {
  right: 0;
  bottom: calc(var(--pf-c-nav__link--before--BorderBottomWidth) * -1);
  left: 0;
  border-color: var(--pf-c-nav__link--before--BorderColor);
  border-bottom-width: var(--pf-c-nav__link--before--BorderBottomWidth);
}
.pf-c-nav__link::after {
  top: 0;
  bottom: 0;
  left: 0;
  border: 0 solid;
  border-color: var(--pf-c-nav__link--after--BorderColor);
  border-left-width: var(--pf-c-nav__link--after--BorderLeftWidth);
}
.pf-c-nav__link:hover, .pf-c-nav__link.pf-m-hover {
  color: var(--pf-c-nav__link--hover--Color);
  background-color: var(--pf-c-nav__link--hover--BackgroundColor);
}
.pf-c-nav__link:hover::before, .pf-c-nav__link.pf-m-hover::before {
  border-bottom-width: var(--pf-c-nav__link--hover--before--BorderBottomWidth);
}
.pf-c-nav__link:hover::after, .pf-c-nav__link.pf-m-hover::after {
  border-color: var(--pf-c-nav__link--hover--after--BorderColor);
  border-left-width: var(--pf-c-nav__link--hover--after--BorderLeftWidth);
}
.pf-c-nav__link:focus {
  color: var(--pf-c-nav__link--focus--Color);
  background-color: var(--pf-c-nav__link--focus--BackgroundColor);
}
.pf-c-nav__link:focus::before {
  border-bottom-width: var(--pf-c-nav__link--focus--before--BorderBottomWidth);
}
.pf-c-nav__link:focus::after {
  border-color: var(--pf-c-nav__link--focus--after--BorderColor);
  border-left-width: var(--pf-c-nav__link--focus--after--BorderLeftWidth);
}
.pf-c-nav__link:active {
  color: var(--pf-c-nav__link--active--Color);
  background-color: var(--pf-c-nav__link--active--BackgroundColor);
}
.pf-c-nav__link:active::before {
  border-bottom-width: var(--pf-c-nav__link--active--before--BorderBottomWidth);
}
.pf-c-nav__link:active::after {
  border-color: var(--pf-c-nav__link--active--after--BorderColor);
  border-left-width: var(--pf-c-nav__link--active--after--BorderLeftWidth);
}
.pf-c-nav__link.pf-m-current, .pf-c-nav__link.pf-m-current:hover, .pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link {
  color: var(--pf-c-nav__link--m-current--Color);
  background-color: var(--pf-c-nav__link--m-current--BackgroundColor);
}
.pf-c-nav__link.pf-m-current::before, .pf-c-nav__link.pf-m-current:hover::before, .pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link::before {
  border-bottom-width: var(--pf-c-nav__link--m-current--before--BorderBottomWidth);
}
.pf-c-nav__link.pf-m-current::after, .pf-c-nav__link.pf-m-current:hover::after, .pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link::after {
  border-color: var(--pf-c-nav__link--m-current--after--BorderColor);
  border-left-width: var(--pf-c-nav__link--m-current--after--BorderLeftWidth);
}
.pf-c-nav__link, .pf-c-nav__link:hover, .pf-c-nav__link:focus, .pf-c-nav__link:active {
  width: 100%;
  text-decoration: none;
  border: none;
}

.pf-c-nav__subnav {
  --pf-c-nav__link--PaddingTop: var(--pf-c-nav__subnav__link--PaddingTop);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav__subnav__link--PaddingRight);
  --pf-c-nav__link--PaddingBottom: var(--pf-c-nav__subnav__link--PaddingBottom);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav__subnav__link--PaddingLeft);
  --pf-c-nav__link--FontSize: var(--pf-c-nav__subnav__link--FontSize);
  --pf-c-nav__link--hover--after--BorderColor: var(--pf-c-nav__subnav__link--hover--after--BorderColor);
  --pf-c-nav__link--focus--after--BorderColor: var(--pf-c-nav__subnav__link--focus--after--BorderColor);
  --pf-c-nav__link--active--after--BorderColor: var(--pf-c-nav__subnav__link--active--after--BorderColor);
  --pf-c-nav__link--m-current--after--BorderColor: var(--pf-c-nav__subnav__link--m-current--after--BorderColor);
  --pf-c-nav__link--hover--after--BorderLeftWidth: var(--pf-c-nav__subnav__link--hover--after--BorderWidth);
  --pf-c-nav__link--focus--after--BorderLeftWidth: var(--pf-c-nav__subnav__link--focus--after--BorderWidth);
  --pf-c-nav__link--active--after--BorderLeftWidth: var(--pf-c-nav__subnav__link--active--after--BorderWidth);
  --pf-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-c-nav__subnav__link--m-current--after--BorderWidth);
  --pf-c-nav--c-divider--PaddingRight: var(--pf-c-nav__subnav--c-divider--PaddingRight);
  --pf-c-nav--c-divider--PaddingLeft: var(--pf-c-nav__subnav--c-divider--PaddingLeft);
  max-height: var(--pf-c-nav__subnav--MaxHeight);
  padding-bottom: var(--pf-c-nav__subnav--PaddingBottom);
  padding-left: var(--pf-c-nav__subnav--PaddingLeft);
  transition: var(--pf-c-nav--Transition);
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.pf-c-nav__subnav .pf-c-nav__subnav {
  --pf-c-nav__link--FontSize: var(--pf-c-nav__subnav__subnav__link--FontSize);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav__subnav__subnav__link--PaddingLeft);
  --pf-c-nav__subnav--PaddingLeft: var(--pf-c-nav__subnav__subnav--PaddingLeft);
}
.pf-c-nav__subnav.pf-m-flyout {
  width: 100%;
}
.pf-c-nav__item.pf-m-expanded .pf-c-nav__subnav {
  --pf-c-nav__subnav--MaxHeight: var(--pf-c-nav__item--m-expanded__subnav--MaxHeight);
  overflow-y: auto;
  opacity: 1;
}
.pf-c-nav__subnav::-webkit-scrollbar {
  display: none;
}

.pf-c-nav__toggle {
  flex: none;
  padding-right: var(--pf-c-nav__toggle--PaddingRight);
  padding-left: var(--pf-c-nav__toggle--PaddingLeft);
  margin-left: auto;
  font-size: var(--pf-c-nav__toggle--FontSize);
  line-height: 1;
}
.pf-c-nav__toggle.pf-m-start {
  margin-right: var(--pf-c-nav__toggle--m-start--MarginRight);
  margin-left: calc(var(--pf-c-nav__toggle--PaddingRight) * -1);
}

.pf-c-nav__toggle-icon {
  display: inline-block;
  transition: var(--pf-c-nav__toggle-icon--Transition);
}
.pf-c-nav__item.pf-m-expanded .pf-c-nav__toggle-icon {
  transform: rotate(var(--pf-c-nav__item--m-expanded__toggle-icon--Rotate));
}

.pf-c-nav__section {
  --pf-c-nav__item--MarginTop: var(--pf-c-nav__section__item--MarginTop);
  --pf-c-nav__link--PaddingTop: var(--pf-c-nav__section__link--PaddingTop);
  --pf-c-nav__link--PaddingRight: var(--pf-c-nav__section__link--PaddingRight);
  --pf-c-nav__link--PaddingBottom: var(--pf-c-nav__section__link--PaddingBottom);
  --pf-c-nav__link--PaddingLeft: var(--pf-c-nav__section__link--PaddingLeft);
  --pf-c-nav__link--FontSize: var(--pf-c-nav__section__link--FontSize);
  --pf-c-nav__link--before--BorderBottomWidth: var(--pf-c-nav__section__link--before--BorderBottomWidth);
  --pf-c-nav__link--hover--after--BorderColor: var(--pf-c-nav__section__link--hover--after--BorderColor);
  --pf-c-nav__link--focus--after--BorderColor: var(--pf-c-nav__section__link--focus--after--BorderColor);
  --pf-c-nav__link--active--after--BorderColor: var(--pf-c-nav__section__link--active--after--BorderColor);
  --pf-c-nav__link--m-current--after--BorderColor: var(--pf-c-nav__section__link--m-current--after--BorderColor);
  --pf-c-nav__link--hover--after--BorderLeftWidth: var(--pf-c-nav__section__link--hover--after--BorderWidth);
  --pf-c-nav__link--focus--after--BorderLeftWidth: var(--pf-c-nav__section__link--focus--after--BorderWidth);
  --pf-c-nav__link--active--after--BorderLeftWidth: var(--pf-c-nav__section__link--active--after--BorderWidth);
  --pf-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-c-nav__section__link--m-current--after--BorderWidth);
  margin-top: var(--pf-c-nav__section--MarginTop);
  --pf-c-nav--c-divider--MarginBottom: 0;
}
.pf-c-nav__section + .pf-c-nav__section {
  --pf-c-nav__section--MarginTop: var(--pf-c-nav__section--section--MarginTop);
}

.pf-c-nav__section-title {
  padding: var(--pf-c-nav__section-title--PaddingTop) var(--pf-c-nav__section-title--PaddingRight) var(--pf-c-nav__section-title--PaddingBottom) var(--pf-c-nav__section-title--PaddingLeft);
  font-size: var(--pf-c-nav__section-title--FontSize);
  color: var(--pf-c-nav__section-title--Color);
  border-bottom: var(--pf-c-nav__section-title--BorderBottomWidth) solid var(--pf-c-nav__section-title--BorderBottomColor);
}

.pf-c-nav__scroll-button {
  flex: none;
  width: var(--pf-c-nav__scroll-button--Width);
  color: var(--pf-c-nav__scroll-button--Color);
  background-color: var(--pf-c-nav__scroll-button--BackgroundColor);
  border: 0;
  outline-offset: var(--pf-c-nav__scroll-button--OutlineOffset);
  opacity: 0;
  transition: var(--pf-c-nav__scroll-button--Transition);
}
.pf-c-nav__scroll-button::before {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  border: solid var(--pf-c-nav__scroll-button--before--BorderColor);
  border-width: 0 var(--pf-c-nav__scroll-button--before--BorderRightWidth) 0 var(--pf-c-nav__scroll-button--before--BorderLeftWidth);
}
.pf-c-nav__scroll-button:hover {
  color: var(--pf-c-nav__scroll-button--hover--Color);
}
.pf-c-nav__scroll-button:focus {
  color: var(--pf-c-nav__scroll-button--focus--Color);
}
.pf-c-nav__scroll-button:active {
  color: var(--pf-c-nav__scroll-button--active--Color);
}
.pf-c-nav__scroll-button:disabled {
  color: var(--pf-c-nav__scroll-button--disabled--Color);
  border-color: var(--pf-c-nav__scroll-button--disabled--before--BorderColor);
}
.pf-c-nav__scroll-button:nth-of-type(1) {
  --pf-c-nav__scroll-button--before--BorderRightWidth: var(--pf-c-nav__scroll-button--before--BorderWidth);
  margin-right: calc(var(--pf-c-nav__scroll-button--Width) * -1);
  transform: translateX(-100%);
}
.pf-c-nav__scroll-button:nth-of-type(1)::before {
  right: 0;
}
.pf-c-nav__scroll-button:nth-of-type(2) {
  --pf-c-nav__scroll-button--before--BorderLeftWidth: var(--pf-c-nav__scroll-button--before--BorderWidth);
  margin-left: calc(var(--pf-c-nav__scroll-button--Width) * -1);
  transform: translateX(100%);
}
.pf-c-nav__scroll-button:nth-of-type(2)::before {
  left: 0;
}