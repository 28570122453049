.pf-c-avatar {
  --pf-c-avatar--BorderColor: transparent;
  --pf-c-avatar--BorderWidth: 0;
  --pf-c-avatar--BorderRadius: var(--pf-global--BorderRadius--lg);
  --pf-c-avatar--Width: 2.25rem;
  --pf-c-avatar--Height: 2.25rem;
  --pf-c-avatar--m-light--BorderColor: var(--pf-global--BorderColor--dark-100);
  --pf-c-avatar--m-light--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-avatar--m-dark--BorderColor: var(--pf-global--palette--black-700);
  --pf-c-avatar--m-dark--BorderWidth: var(--pf-global--BorderWidth--sm);
  width: var(--pf-c-avatar--Width);
  height: var(--pf-c-avatar--Height);
  border: var(--pf-c-avatar--BorderWidth) solid var(--pf-c-avatar--BorderColor);
  border-radius: var(--pf-c-avatar--BorderRadius);
}
.pf-c-avatar.pf-m-light {
  --pf-c-avatar--BorderColor: var(--pf-c-avatar--m-light--BorderColor);
  --pf-c-avatar--BorderWidth: var(--pf-c-avatar--m-light--BorderWidth);
}
.pf-c-avatar.pf-m-dark {
  --pf-c-avatar--BorderColor: var(--pf-c-avatar--m-dark--BorderColor);
  --pf-c-avatar--BorderWidth: var(--pf-c-avatar--m-dark--BorderWidth);
}