.pf-l-split {
  --pf-l-split--m-gutter--MarginRight: var(--pf-global--gutter);
  display: flex;
  padding: 0;
  margin: 0;
}
.pf-l-split.pf-m-wrap {
  flex-wrap: wrap;
}

.pf-l-split__item.pf-m-fill {
  flex-grow: 1;
}

.pf-l-split.pf-m-gutter > *:not(:last-child) {
  margin-right: var(--pf-l-split--m-gutter--MarginRight);
}