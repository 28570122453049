.pf-u-screen-reader {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pf-u-visible {
  position: static;
  overflow: visible;
  clip: auto;
  white-space: normal;
  border: inherit;
}

.pf-u-hidden {
  display: none !important;
}

@media screen and (min-width: 576px) {
  .pf-u-screen-reader-on-sm {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-visible-on-sm {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-hidden-on-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-screen-reader-on-md {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-visible-on-md {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-hidden-on-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-screen-reader-on-lg {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-visible-on-lg {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-hidden-on-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-screen-reader-on-xl {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-visible-on-xl {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-hidden-on-xl {
    display: none !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-screen-reader-on-2xl {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-visible-on-2xl {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-hidden-on-2xl {
    display: none !important;
  }
}